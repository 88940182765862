<template>
  <div>
    <van-cell-group>
      <van-cell
        v-for="(item, index) in list"
        :key="index"
        :title="item.nodeName"
      >
        <template #label>
          <div>
            <div
              :style="{
                overflow: 'hidden',
                transition: 'height 0.5s',
                height: item.show ? 'auto' : '40px',
              }"
            >
              <van-button
                class="m-b-10 m-r-10"
                size="small"
                v-for="(device, idx) in item.devices"
                :key="idx"
                :type="device.caveatLevel | formatType"
                @click="toDetail(item.nodeId, device.vmCode)"
              >
                {{ device.deviceCode }}
              </van-button>
            </div>
            <div
              v-if="item.devices.length > 3"
              class="w-100 m-t-10 f-cc bg-white"
              @click="onSwitch(index)"
            >
              <van-icon :name="'arrow-' + (item.show ? 'up' : 'down')" />
            </div>
          </div>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>
<script>
import util from '@/utils/util'

export default {
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.listCaveatDemandVm()
  },
  methods: {
    async listCaveatDemandVm() {
      const { data } = await this.$api.listCaveatDemandVm()
      console.log('listCaveatDemandVm===>', data)
      this.list = data.map((v) => ({
        ...v,
        show: false,
        devices: v.replenishDemandStatVos.sort(
          (a, b) =>
            util.getWarnStat(b.caveatLevel) - util.getWarnStat(a.caveatLevel)
        ),
      }))
    },
    onSwitch(index) {
      this.list[index].show = !this.list[index].show
    },
    toDetail(nodeId, vmCode) {
      this.$router.push({ path: '/warningDetail', query: { nodeId, vmCode } })
    },
  },
}
</script>